<template>
  <div>
    <el-row v-loading="loading">
      <el-col :span="7" class="desktop-cols">
        <el-card>
          <label style="line-height: 40px">За период</label>
          <el-select
            v-model="params.period_id"
            placeholder="Выберите тип КПР"
            style="float: right"
            @change="fetchData">
            <el-option
              v-for="item in periods"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
          <el-divider/>
          <el-checkbox v-model="colored" style="margin-left: 20px">Подсветка</el-checkbox>
          <el-divider/>
          <div>
            <p><b>Показатели</b></p>
            <label>{{`${stat.pkis.summary.completed || 0}/${stat.pkis.summary.should_be_entered || 0}`}}</label>
            <el-progress :percentage="calcPercent(stat.pkis.summary.completed, stat.pkis.summary.should_be_entered)" color="#1ab394"></el-progress>
            <el-divider/>
            <p><b>Комментарии (1, 2, 3 балла)</b></p>
            <label>{{`${stat.comments.summary.completed || 0}/${stat.comments.summary.should_be_entered || 0}`}}</label>
            <el-progress :percentage="calcPercent(stat.comments.summary.completed, stat.comments.summary.should_be_entered)" color="#1ab394"></el-progress>
            <el-divider/>
            <p><b>Файлы PDF (да/нет)</b></p>
            <label>{{`${stat.files.summary.completed || 0}/${stat.files.summary.should_be_entered || 0}`}}</label>
            <el-progress :percentage="calcPercent(stat.files.summary.completed, stat.files.summary.should_be_entered)" color="#1ab394"></el-progress>
            <el-divider/>
          </div>
          <el-tabs @tab-click="chartTabClick">
            <el-tab-pane label="Показатели">
              <Chart v-if="!chartLoading" :options="chartPkisData" class="chart" height="400px"/>
            </el-tab-pane>
            <el-tab-pane label="Комментарии">
              <Chart v-if="!chartLoading" :options="chartCommentsData" class="chart" height="400px"/>
            </el-tab-pane>
            <el-tab-pane label="Файлы PDF">
              <Chart v-if="!chartLoading" :options="chartFilesData" class="chart" height="400px"/>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
      <el-col :span="17" class="desktop-cols">
        <el-tabs>
          <el-tab-pane label="Показатели">
            <el-col :span="12" class="desktop-cols">
              <el-card>
                <label style="line-height: 40px">Источники информации</label>
                <el-divider/>
                <simple-table
                  :list="stat.pkis.operators"
                  name="pkis.operators"
                  :show-pagination="false"
                  :columns="ebospColumns"
                  :colored="colored"/>
              </el-card>
              <el-card style="margin-top: 10px">
                <label style="line-height: 40px">Блоки</label>
                <el-divider/>
                <simple-table
                  :list="stat.pkis.blocks"
                  name="pkis.blocks"
                  :show-pagination="false"
                  :columns="blockColumns"
                  :colored="colored"/>
              </el-card>
            </el-col>
            <el-col :span="12" class="desktop-cols">
              <el-card>
                <label style="line-height: 40px">ИОГВ</label>
                <el-divider/>
                <simple-table
                  :list="stat.pkis.ebosps"
                  name="pkis.ebosps"
                  :show-pagination="false"
                  :columns="ebospColumns"
                  :colored="colored"/>
              </el-card>
            </el-col>
          </el-tab-pane>
          <el-tab-pane label="Комментарии">
            <el-col :span="12" class="desktop-cols">
              <el-card>
                <label style="line-height: 40px">Источники информации</label>
                <el-divider/>
                <simple-table
                  :list="stat.comments.operators"
                  name="comments.operators"
                  :show-pagination="false"
                  :columns="ebospColumns"
                  :colored="colored"/>
              </el-card>
            </el-col>
            <el-col :span="12" class="desktop-cols">
              <el-card>
                <label style="line-height: 40px">ИОГВ</label>
                <el-divider/>
                <simple-table
                  :list="stat.comments.ebosps"
                  name="comments.ebosps"
                  :show-pagination="false"
                  :columns="ebospColumns"
                  :colored="colored"/>
              </el-card>
            </el-col>
          </el-tab-pane>
          <el-tab-pane label="Файлы">
            <el-card>
              <span>* Учитывается факт наличия хотя бы одного файла в формате PDF в разделе «Итог» за указанный отчетный период, вне зависимости от содержания файла и т. д.</span>
            </el-card>
            <el-col :span="12" class="desktop-cols">
              <el-card>
                <label style="line-height: 40px">Источники информации</label>
                <el-divider/>
                <simple-table
                  :list="stat.files.operators"
                  name="files.operators"
                  :show-pagination="false"
                  :columns="ebospFileColumns"
                  :colored="colored"/>
              </el-card>
            </el-col>
            <el-col :span="12" class="desktop-cols">
              <el-card>
                <label style="line-height: 40px">ИОГВ</label>
                <el-divider/>
                <simple-table
                  :list="stat.files.ebosps"
                  name="files.ebosps"
                  :show-pagination="false"
                  :columns="ebospFileColumns"
                  :colored="colored"/>
              </el-card>
            </el-col>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Chart from '@/components/Chart'
import SimpleTable from '@/components/SimpleTable'
import { getDesktopKP } from '@/api/api'
import { getPeriods } from '@/api/catalogs'

export default {
  components: {
    Chart,
    SimpleTable
  },
  data () {
    return {
      periods: [],
      params: {
        period_id: null
      },
      colored: false,
      loading: true,
      chartLoading: true,
      sources: [],
      iogv: [],
      stat: {
        pkis: {
          operators: [],
          blocks: [],
          ebosps: [],
          summary: {
            should_be_entered: null,
            entered: null
          }
        },
        comments: {
          operators: [],
          ebosps: [],
          summary: {
            should_be_entered: null,
            entered: null
          }
        },
        files: {
          operators: [],
          ebosps: [],
          summary: {
            should_be_entered: null,
            entered: null
          }
        }
      },
      ebospColumns: [
        {
          field: 'short_name',
          label: 'ИОГВ',
          align: 'left'
        },
        {
          field: 'should_be_entered',
          type: 'stat',
          label: 'Должно быть введено'
        },
        {
          field: 'completed',
          type: 'stat',
          label: 'Уже введено',
          width: '100px'
        },
        {
          field: 'percent',
          label: 'Процент',
          type: 'percent'
        }
      ],
      ebospFileColumns: [
        {
          field: 'short_name',
          label: 'ИОГВ',
          align: 'left'
        },
        {
          field: 'should_be_entered',
          label: 'Должно быть введено'
        },
        {
          field: 'completed',
          label: 'Уже введено',
          width: '100px'
        },
        {
          field: 'percent',
          label: 'Процент',
          type: 'percent'
        }
      ],
      blockColumns: [
        {
          field: 'name',
          label: 'Блок',
          align: 'left'
        },
        {
          field: 'should_be_entered',
          label: 'Должно быть введено'
        },
        {
          field: 'completed',
          label: 'Уже введено',
          width: '100px'
        },
        {
          field: 'percent',
          label: 'Процент',
          type: 'percent'
        }
      ]
    }
  },
  mounted () {
    this.fetchData()
    this.fetchPeriods()
  },
  computed: {
    chartPkisData () {
      return {
        title: {
          text: 'Ход заполнения',
          subtext: `${this.stat.pkis.summary.completed}/${this.stat.pkis.summary.should_be_entered}`,
          left: 'left',
          textStyle: {
            color: '#303133',
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
          },
          subtextStyle: {
            color: '#303133',
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
          }
        },
        tooltip: {
          trigger: 'item',
          valueFormatter: (value) => value
        },
        series: [
          {
            type: 'pie',
            radius: '80%',
            top: 50,
            data: [
              { value: this.stat.pkis.summary.completed, name: 'Заполнено' },
              { value: this.stat.pkis.summary.should_be_entered - this.stat.pkis.summary.completed, name: 'Не заполнено' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    },
    chartCommentsData () {
      return {
        title: {
          text: 'Ход заполнения',
          subtext: `${this.stat.comments.summary.completed}/${this.stat.comments.summary.should_be_entered}`,
          left: 'left',
          textStyle: {
            color: '#303133',
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
          },
          subtextStyle: {
            color: '#303133',
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
          }
        },
        tooltip: {
          trigger: 'item',
          valueFormatter: (value) => value
        },
        series: [
          {
            type: 'pie',
            radius: '80%',
            top: 50,
            data: [
              { value: this.stat.comments.summary.completed, name: 'Заполнено' },
              { value: this.stat.comments.summary.should_be_entered - this.stat.comments.summary.completed, name: 'Не заполнено' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    },
    chartFilesData () {
      return {
        title: {
          text: 'Ход заполнения',
          subtext: `${this.stat.files.summary.completed}/${this.stat.files.summary.should_be_entered}`,
          left: 'left',
          textStyle: {
            color: '#303133',
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
          },
          subtextStyle: {
            color: '#303133',
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
          }
        },
        tooltip: {
          trigger: 'item',
          valueFormatter: (value) => value
        },
        series: [
          {
            type: 'pie',
            radius: '80%',
            top: 50,
            data: [
              { value: this.stat.files.summary.completed, name: 'Заполнено' },
              { value: this.stat.files.summary.should_be_entered - this.stat.files.summary.completed, name: 'Не заполнено' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  methods: {
    chartTabClick () {
      this.chartLoading = true
      this.$nextTick(() => {
        this.chartLoading = false
      })
    },
    fetchData () {
      this.loading = true
      this.chartLoading = true
      getDesktopKP(this.params).then(data => {
        this.stat = data.data.stat
        this.loading = false
        this.chartLoading = false
        this.params.period_id = data.data.period_id
      })
    },
    fetchPeriods () {
      getPeriods({ is_monthly: true }).then(response => {
        this.periods = response?.data.data
      })
    },
    calcPercent (a, b) {
      if (a) {
        return Math.round(((a / b) * 100))
      }
      return 0
    }
  }
}

</script>

<style scoped>
.desktop-cols {
  padding: 5px;
}
.chart {
  z-index: 100;
}
.el-tabs >>> .el-tabs__header {
  margin-left: 5px;
}
</style>
